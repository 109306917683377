import { AMOUNT, WHEN, PURPOSE, TURNOVER, SEARCH, YEARS_TRADING, ASSETS } from '@/helpers/constants/question-slugs'

// JOURNEY (Assets q)
// loan amount -> company search -> years trading (sole trader only) -> turnover -> assets (if turnover > 50k) -> contact details

export default {
  [AMOUNT]: () => WHEN,
  [WHEN]: () => PURPOSE,
  [PURPOSE]: () => SEARCH,
  [SEARCH]: (answer) => {
    return answer.result.isManual
      ? YEARS_TRADING
      : TURNOVER
  },
  [YEARS_TRADING]: () => TURNOVER,
  [TURNOVER]: () => ASSETS,
  [ASSETS]: () => null
}
