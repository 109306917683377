import { ASSETS_QUESTION, NERDWALLET_ASSETS, USW_THINK_TEST } from '@/helpers/constants/journey-names'
import journeyAssetsQuestion from '@/helpers/journeys/journey-assets-question.js'
import journeyUswitchThinkTest from '@/helpers/journeys/journey-think-uswitch-business-type.js'
import questionData from '@/helpers/questions/question-sets/questionData'
import journeyControl from '../journeys/journey-control'
import journeyNerdWalletAssets from '../journeys/journey-nerdwallet-assets.js'

const debug = require('debug')('fh:helpers:get-next-question')

function getJourneyStrategy (journeyId) {
  // use switch to test multi journeys
  switch (journeyId) {
    case NERDWALLET_ASSETS :
      return journeyNerdWalletAssets

    case ASSETS_QUESTION :
      return journeyAssetsQuestion

    case USW_THINK_TEST :
      return journeyUswitchThinkTest

    default:
      return journeyControl
  }
}

export default function getNextQuestion (currentQuestionSlug, currentAnswer, journeyId) {
  debug('journeyId', journeyId)
  // journeyID from url e.g ?journey=3
  const journey = getJourneyStrategy(journeyId)

  debug('currentQuestionSlug', currentQuestionSlug)

  const nextQuestionSlug = journey[currentQuestionSlug](currentAnswer)

  debug('nextQuestionSlug', nextQuestionSlug)

  if (!nextQuestionSlug) {
    return null
  }

  return questionData.find(x => x.slug === nextQuestionSlug)
}
