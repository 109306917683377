import { AMOUNT, PURPOSE, BUSINESS_TYPE, BUSINESS_INPUT, SEARCH, TURNOVER, WHEN, YEARS_TRADING } from '@/helpers/constants/question-slugs'
import { LIMITED_COMPANY } from '@/helpers/questions/options/typeQuestionData'
// JOURNEY (think uswitch test q)
// loan amount -> When -> purpose -> business input -> business type -> if limited -> company search -> else -> business input -> years trading (sole trader only) -> turnover -> contact details

export default {
  [AMOUNT]: () => WHEN,
  [WHEN]: () => PURPOSE,
  [PURPOSE]: () => BUSINESS_TYPE,
  [BUSINESS_TYPE]: (answer) => {
    return answer.result === LIMITED_COMPANY 
      ? SEARCH
      : BUSINESS_INPUT
  },
  [BUSINESS_INPUT]: () => YEARS_TRADING,
  [SEARCH]: (answer) => {
    return answer.result.isManual
      ? YEARS_TRADING
      : TURNOVER
  },
  [YEARS_TRADING]: () => TURNOVER,
  [TURNOVER]: () => null
}
