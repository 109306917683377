import { AMOUNT, WHEN, PURPOSE, TURNOVER, SEARCH, YEARS_TRADING } from '@/helpers/constants/question-slugs'
// JOURNEY control
// loan amount -> when -> purpose -> company search -> years trading (sole trader only) -> turnover -> contact details

export default {
  [AMOUNT]: () => WHEN,
  [WHEN]: () => PURPOSE,
  [PURPOSE]: () => SEARCH,
  [SEARCH]: (answer) => {
    return answer.result.isManual
      ? YEARS_TRADING
      : TURNOVER
  },
  [YEARS_TRADING]: () => TURNOVER,
  [TURNOVER]: () => null
}
